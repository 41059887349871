<template>
  <div>
    <Loading :loading="false" />
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";

export default {
  components: {
    Loading,
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
</style>
